import React, { useState } from "react";
import MainLogo from "../../components/MainLogo";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import landing_left_image from "../../assets/start_shop.jpg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LoginHelper from "../../helpers/LoginHelper";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Backdrop } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { CUSTOMER_BY_CODE } from "../../queries-mutations/customers";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginCodePopup from "../../components/LoginCodePopup";
import { Translate } from "react-auto-translate";
import TranslationFlag from "../../components/TranslationFlag";
import { setUserToken, unsetToken, unsetUserToken } from "../../actions";

const styles = makeStyles(theme => ({
  mainContainer: {
    height: "100vh"
  },
  leftContainer: {
    backgroundImage: `url(${landing_left_image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100vh",
    // height: "100vh",
    backgroundPosition: "center",
    "@media (max-width: 768px)": {
      height: "40vh"
    }
  },
  leftContainerChild: {
    width: "100%",
    height: "100%"
  },
  leftTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      height: "40vh"
    }
  },
  leftText: {
    textAlign: "center",
    position: "relative",
    color: "black",
    padding: "0px 30px",
    backgroundColor: "rgba(255, 255, 255, .4)",
    margin: 0
  },
  leftHeader: {
    fontSize: 35,
    paddingBottom: 45,
    paddingTop: 35,
    fontWeight: 300,
    "@media (min-width: 769px)": {
      marginTop: "35vh"
    },
    "@media (max-width: 768px)": {
      fontSize: 20
    }
  },
  leftSubHeader: {
    fontWeight: 300,
    fontSize: 25,
    paddingBottom: 45,
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  rightTopContainer: {
    position: "absolute",
    top: 10,
    right: 10
  },
  rightMainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "@media (min-width: 769px)": {
      marginLeft: "25%",
      height: "100vh"
    },
    "@media (max-width: 768px)": {
      padding: 10
    }
  },
  codeInput: {
    borderRadius: 0,
    minWidth: 350
  },
  codeInputError: {
    borderRadius: 0,
    minWidth: 350,
    borderColor: "red"
  },
  loginButton: {
    marginTop: 10,
    padding: "10px 25px",
    backgroundColor: "#f2994a",
    borderRadius: 3
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

function Landing(props) {
  let history = useHistory();

  let { urlCode } = useParams();

  const [code, setCode] = useState(urlCode ? urlCode : "");
  const [requestCode, setRequestCode] = useState(urlCode ? urlCode : "");

  const { loading, error, data } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: requestCode }
  });

  if (data && data.customerByCode) {
    console.log(data.customerByCode.token,"==> token");
    props.dispatch(setUserToken(data.customerByCode.token));
    props.dispatch(unsetToken())
  }

  const handleTextChange = event => {
    setCode(event.target.value);
  };

  const authUser = () => {
    setRequestCode(code.trim());
  };

  const classes = styles();

  if (props.userInformation.auth) {
    history.push("/users");
  }

  return (
    <Grid container spacing={0} className={classes.mainContainer}>
      {data && data.customerByCode && (
        <LoginHelper customerByCode={data.customerByCode} />
      )}
      <Grid item xs={12} md={5} className={classes.leftContainer}>
      </Grid>
      <Grid item xs={12} md={7}>
        <div className={classes.rightTopContainer}>
          <LoginCodePopup />
        </div>
        <form
          className={classes.rightMainContainer}
          onSubmit={event => {
            event.preventDefault();
            authUser();
          }}
        >
          <h2>
            <Translate>
              Inloggen op de geschenkenmarkt van Zeg het met Duiten
            </Translate>
          </h2>
          <p>
            <Translate>Vul je inlogcode hieronder in.</Translate>
          </p>
          <TextField
            id="code"
            name="code"
            label="Code"
            variant="outlined"
            value={code}
            className={classes.codeInput}
            error={
              error || (requestCode !== "" && data && !data.customerByCode)
            }
            onChange={handleTextChange}
            helperText={
              error ? (
                <Translate>Er ging iets mis, probeer het later weer</Translate>
              ) : requestCode !== "" && data && !data.customerByCode ? (
                <Translate>Deze code is niet bij ons bekend</Translate>
              ) : (
                ""
              )
            }
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={requestCode !== "" && loading}
            BackdropComponent={Backdrop}
          >
            <CircularProgress />
          </Modal>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            className={classes.loginButton}
          >
            <Translate>Inloggen</Translate>
          </Button>
        </form>
      </Grid>
      <TranslationFlag />
    </Grid>
  );
}

const mapStateToProps = state => {
  const { userInformation } = state;
  return { userInformation };
};

export default connect(mapStateToProps)(Landing);
