import IconButton from "@material-ui/core/IconButton";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { logoutUser, unsetUserToken } from "../actions";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import { Translate } from "react-auto-translate";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ViewListIcon from "@material-ui/icons/ViewList";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import HomeIcon from "@material-ui/icons/Home";
import { LOGOUT_USER } from "../queries-mutations/adminAuth";
import { useMutation } from "@apollo/react-hooks";

const styles = makeStyles(theme => ({
  genericIcon: {
    fontSize: 30
  }
}));

function HeaderHamburger(props) {
  const classes = styles();
  const [httpLogout] = useMutation(LOGOUT_USER);
  const logout = async () => {
    try {
        const response = await httpLogout();
        if (response.data?.logoutUser) {
            props.logoutUserForced();  // Clear user state
            console.log("Logout successful:", response.data.logoutUser);
        } else {
            console.error("Logout failed.");
        }
    } catch (error) {
        console.error("Error during logout:", error);
    }
};

  const [hamburgerMenu, setHamburgerMenu] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setHamburgerMenu(open);
  };

  const list = anchor => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={RouterLink} to={userRoutes.SHOP}>
          <ListItemIcon>
            <HomeIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
        <ListItem button component={RouterLink} to={userRoutes.SUCCESS}>
          <ListItemIcon>
            <ViewListIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText primary={<Translate>Mijn bestellingen</Translate>} />
        </ListItem>
        <ListItem button component={RouterLink} to={userRoutes.OVER}>
          <ListItemIcon>
            <ContactSupportIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText primary={<Translate>Veelgestelde vragen</Translate>} />
        </ListItem>
        <ListItem button onClick={logout} >
          <ListItemIcon>
            <ExitToAppIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText primary={<Translate>Uitloggen</Translate>} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment key={"right"}>
      <IconButton color="primary" onClick={toggleDrawer(true)} style={{ padding: "unset"}}>
        <MenuIcon className={classes.genericIcon} />
      </IconButton>
      <Drawer 
        anchor={"right"}
        open={hamburgerMenu}
        onClose={toggleDrawer(false)}
      >
        {list("right")}
      </Drawer>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    logoutUserForced: () => {
      dispatch(logoutUser());
      dispatch(unsetUserToken());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(HeaderHamburger);
