import Paper from "@material-ui/core/Paper";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { useQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import { ORDERS_BY_DATE } from "../../queries-mutations/adminAuth";
import OrdersByDateTable from "../../components/OrdersByDateTable";

export default function OrdersPerDag(props) {
  const [selectedDate, setSelectedDate] = React.useState("");
  const { loading, error, data, refetch } = useQuery(ORDERS_BY_DATE, {
    variables: { date: selectedDate },
    skip: selectedDate.trim() === "",
    partialRefetch: true
  });

  const handleDateChange = e => {
    setSelectedDate(e.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 25 }}>
          <h1>Orders per dag</h1>
          <TextField
            id="date"
            label="Datum"
            type="date"
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Paper>
        {data && <OrdersByDateTable data={data} date={selectedDate} />}
      </Grid>
    </Grid>
  );
}
