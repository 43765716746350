import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { editModalStyle } from "../styles/editModalStyle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/react-hooks";
import CodeGenerator from "../helpers/CodeGenerator";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER
} from "../queries-mutations/customers";
import ConfirmationRequiredButton from "./ConfirmationRequiredButton";



export default function EditCustomerModal(props) {
  console.log(props, "==> props");
  const classes = editModalStyle();

  const [customer, setCustomer] = useState({
    firstName: props.customer ? props.customer.firstName : "",
    insertion: props.customer ? props.customer.insertion : "",
    lastName: props.customer ? props.customer.lastName : "",
    email: props.customer ? props.customer.email : "",
    gender: props.customer ? props.customer.gender : "",
    duiten: props.customer
      ? props.customer.duiten
      : props.duiten
      ? props.duiten
      : 0
  });
  const [error, setError] = useState("");
  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);

  const [initiatedDelete, setInitiatedDelete] = useState(false);

  const initiateDelete = () => {
    setInitiatedDelete(true);
  };

  const cancelDelete = () => {
    setInitiatedDelete(false);
  };

  const confirmDelete = () => {
    // deleteCustomer({ variables: { id: props.customer.id } });
    deleteCustomer({ variables: { id: props.customer.id } })
    .then(() => {
      console.log("deleted successfully");
      // props.onClose();
      // Reload the page if the deletion is successful
      window.location.reload();
    })
    .catch((error) => {
      // Show an alert message with the error if the deletion fails
      alert(`Error deleting customer: ${error.message}`);
    });
    // props.onClose();
    // window.location.reload();
  };

  // const handleTextChange = event => {
  //   setCustomer({ ...customer, [event.target.name]: event.target.value });
  // };

  const handleTextChange = (event) => {
    const { name, value } = event.target;

   
    if (parseInt(value) > props.companyDuiten && name === "duiten") {
   
      setError("The amount cannot exceed the available company duiten.");
    } else {
      if(name === "duiten"){
 setError("");
}
      // Update the state with the new value if it's valid
      setCustomer({ ...customer, [name]: value });
    }
  };
  const handleGenderChange = event => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
    console.log(customer);
  };

  const generateCode = () => {
    return props.companyCode + "-" + CodeGenerator(6);
  };

  const saveCustomer = () => {
    if (props.customer) {
      updateCustomer({
        variables: {
          id: props.customer.id,
          firstName: customer.firstName,
          insertion: customer.insertion,
          lastName: customer.lastName,
          email: customer.email,
          gender: customer.gender,
          duiten: parseInt(customer.duiten)
        }
      });
    } else {
      let code = generateCode();
      while (props.codeExists(code)) {
        code = generateCode();
      }

      createCustomer({
        variables: {
          code: code,
          firstName: customer.firstName,
          insertion: customer.insertion,
          lastName: customer.lastName,
          email: customer.email,
          gender: customer.gender,
          duiten: parseInt(customer.duiten),
          company: props.companyId
        }
      });
    }

    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.parent}>
      <Paper className={classes.smallModalStyle}>
        <h1>Nieuwe klant</h1>
        <form
          onSubmit={event => {
            event.preventDefault();
            saveCustomer();
          }}
        >
          <TextField
            variant="outlined"
            label="Voornaam"
            name="firstName"
            required
            defaultValue={customer.firstName}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            variant="outlined"
            label="Tussenvoegsel"
            name="insertion"
            defaultValue={customer.insertion}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            variant="outlined"
            label="Achternaam"
            name="lastName"
            required
            defaultValue={customer.lastName}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            required
            defaultValue={customer.email}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <FormControl className={classes.input}>
              <FormLabel id="demo-controlled-radio-buttons-group">
               Geslacht
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="gender"
                value={customer.gender}
                onChange={handleGenderChange}
              >
                <FormControlLabel
                  value="Man"
                  control={<Radio />}
                  label="Man"
                />
                <FormControlLabel
                  value="Vrouw"
                  control={<Radio />}
                  label="Vrouw"
                />
                  <FormControlLabel
                  value="Geen keuze"
                  control={<Radio />}
                  label="Geen keuze"
                />
              </RadioGroup>
            </FormControl>
          <br />
          <TextField
        required
        variant="outlined"
        label="Aantal duiten"
        name="duiten"
        value={customer.duiten} // Use value instead of defaultValue for controlled component
        type="number"
        max={props.companyDuiten}
        className="input" // Add any custom styles if needed
        onChange={handleTextChange}
        error={!!error} // Shows error if there's an error
        helperText={error} // Displays the error message
      />
          <br />

          {props.customer && (
            <ConfirmationRequiredButton
              initiated={initiatedDelete}
              cancel={cancelDelete}
              initiate={initiateDelete}
              confirm={confirmDelete}
              confirmationMessage={
                "Weet je zeker dat je deze gebruiker wilt verwijderen? Alle gerelateerde informatie word ook verwijderd."
              }
              cancelMessage={"Annuleren"}
              confirmMessage={"Verwijderen"}
              initialMessage={"Verwijderen"}
            />
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.input}
            size="large"
          >
            Opslaan
          </Button>
        </form>
      </Paper>
    </Modal>
  );
}
