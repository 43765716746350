import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
  query loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password)
  }
`;


export const LOGOUT_USER = gql`
    mutation logoutUser {
        logoutUser
    }
`;

export const LOGOUT_ADMIN = gql`
    mutation adminLogout {
      adminLogout
    }
`;


export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    updateUser(
      id: $id
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
    ) {
      firstName
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $role: Role!
  ) {
    createUser(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      role: $role,
    ) {
      firstName
    }
  }
`;

export const ORDERS_BY_DATE = gql`
  query ordersByDate($date: DateTime!) {
    ordersByDate(date: $date) {
      products {
        hasProduct {
          id
          name
          code
          stockAmount
        }
      }
    }
  }
`;

export const USERS = gql`
  {
    users {
      id
      firstName
      lastName
      email
    }
  }
`;

export const CONTACT_FORMS = gql`
  {
    contactForms {
      name
      phone
      email
      employer
      code
      details
    }
  }
`;

export const UNFINISHED_CONTACT_FORMS = gql`
  query unfinishedContactForms($onlyUnfinished: Boolean!) {
    unfinishedContactForms(onlyUnfinished: $onlyUnfinished) {
      id
      name
      phone
      email
      employer
      code
      details
      done
    }
  }
`;

export const FINISH_CONTACT_FORM = gql`
  mutation finishContactForm($id: ID!) {
    finishContactForm(id: $id) {
      id
    }
  }
`;
