import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { adminMenuStyles } from "../styles/AdminMenu";
import { Link as RouterLink } from "react-router-dom";
import { unsetToken } from "../actions";
import { connect } from "react-redux";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CategoryIcon from "@material-ui/icons/Category";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { LOGOUT_ADMIN } from "../queries-mutations/adminAuth";
import { useMutation } from "@apollo/react-hooks";

function AdminMenu(props) {
  const classes = adminMenuStyles();

    const [httpLogout] = useMutation(LOGOUT_ADMIN);
    const logout = async () => {
      try {
          const response = await httpLogout();
          if (response.data?.adminLogout) {
            props.logoutAdmin();
              // props.logoutUserForced();  // Clear user state
              console.log("Logout successful:", response.data.adminLogout);
          } else {
              console.error("Logout failed.");
          }
      } catch (error) {
          console.error("Error during logout:", error);
      }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem
            component={RouterLink}
            to={"/Admin/banners"}
            button
            key="banners"
          >
            <ListItemIcon>
              <PhotoLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Banners" />
          </ListItem>
          <ListItem
            component={RouterLink}
            to={"/Admin/producten"}
            button
            key="producten"
          >
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText primary="Producten" />
          </ListItem>
          <ListItem
            component={RouterLink}
            to={"/Admin/categorieen"}
            button
            key="categorieen"
          >
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Categorieën" />
          </ListItem>
          <ListItem
            component={RouterLink}
            to={"/Admin/orders"}
            button
            key="orders"
          >
            <ListItemIcon>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            component={RouterLink}
            to={"/Admin/bedrijven"}
            button
            key="bedrijven"
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Bedrijven" />
          </ListItem>
          <ListItem
            component={RouterLink}
            to={"/Admin/vragen"}
            button
            key="vragen"
          >
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="Vragen" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            component={RouterLink}
            to={"/Admin/gebruikers"}
            button
            key="gebruikers"
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Gebruikers" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="logout" onClick={logout} >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Uitloggen" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    logoutAdmin: () => {
      dispatch(unsetToken());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AdminMenu);
