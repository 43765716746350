import React from "react";
import Paper from "@material-ui/core/Paper";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadAllOrderPickerLists from "./DownloadAllOrderPickerLists";
import { Typography } from "@material-ui/core";
import SingleUserInfoAdmin from "./SingleUserInfoAdmin";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useMutation } from "@apollo/react-hooks";
import { PROCESS_ORDER } from "../queries-mutations/customers";

const styles = makeStyles(() => ({
  margin: { marginBottom: 20 },
  downloadLink: {
    color: "white",
    textDecoration: "none !important",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  checkbox: { marginTop: 15 }
}));

export default function AdminOrderOverview(props) {
  const classes = styles();
  const [showProcessed, setHideProcessed] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [processOrder] = useMutation(PROCESS_ORDER);
  const [pdfKey, setPdfKey] = React.useState(Date.now()); // Unique key for re-rendering PDFDownloadLink

  const handleChange = (event) => {
    setHideProcessed(event.target.checked);
  };

  const handleProcessOrders = async () => {
    try {
      await Promise.all(
        props.company.employees.map(async (orderPickerList) => {
          if (
            orderPickerList.order.length > 0 &&
            !orderPickerList.order[orderPickerList.order.length - 1].processed
          ) {
            await processOrder({
              variables: { id: orderPickerList.order[0].id }
            });
          }
        })
      );
      setIsProcessed(true);
      setPdfKey(Date.now());
     setTimeout(() => {
      setIsProcessed(false);
     }, 500);
    } catch (error) {
      console.error("Error processing orders:", error);
      alert("Failed to process some orders. Please try again.");
    }
  };

  return (
    <div>
      <Paper className={classes.margin}>
        <h1>Orders</h1>

        <Typography
          className={classes.downloadLink}
          onClick={handleProcessOrders}
          style={{
            textDecoration: "none",
            marginBottom: 20,
            border: "1px solid #0d7c5f",
            borderRadius: 5,
            padding: "10px 15px",
            color: "white",
            backgroundColor: "#0d7c5f"
          }}
        >
          Download orderpicker lijsten
        </Typography>
         <br />
        
        {isProcessed && (
          <PDFDownloadLink
            key={pdfKey} // Forces re-render
            document={
              <DownloadAllOrderPickerLists
                data={props.company}
                showProcessed={showProcessed}
              />
            }
            fileName={`${props.company.name}.pdf`}
          >
            {({ url, loading }) =>
              !loading && (
                <a
                  id="pdfDownloadLink"
                  href={url}
                  download
                  style={{ display: "none" }}
                  ref={(el) => el && el.click()} // Auto-trigger
                >
                  Download PDF
                </a>
              )
            }
          </PDFDownloadLink>
        )}

     
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={showProcessed}
              onChange={handleChange}
              checkedIcon={<VisibilityIcon />}
              icon={<VisibilityOffIcon />}
            />
          }
          label={
            showProcessed
              ? "Verwerkte orders zichtbaar"
              : "Verwerkte orders verborgen"
          }
        />
      </Paper>
    
      {props.company.employees.map((employee) =>
        employee.order.length > 0 &&
        (showProcessed || employee.order.some((order) => !order.processed)) ? (
          <SingleUserInfoAdmin
            key={employee.id}
            hideProcessed={showProcessed}
            companyName={props.company.name}
            companyDuiten={props.company.duiten}
            companyEmail={props.company.emailId}
            data={employee}
          />
        ) : null
      )}
    </div>
  );
}
