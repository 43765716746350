import { combineReducers } from "redux";
import userInformation from "./userInformation";
import cart from "./cart";
import adminAuth from "./adminAuth";
import language from "./language";
import userReducer from "./userReducer";

export default combineReducers({
  userInformation,
  cart,
  adminAuth,
  language,
  userReducer
});
