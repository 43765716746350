export const loginUser = (firstName, lastName, id, email, code, duiten) => ({
  type: "LOGIN_USER",
  firstName,
  lastName,
  id,
  email,
  code,
  duiten
});

export const logoutUser = () => ({
  type: "LOGOUT_USER"
});

export const setDuiten = duiten => ({
  type: "SET_DUITEN",
  duiten
});

export const UserInformation = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SET_DUITEN: "SET_DUITEN"
};

export const addToCart = (id, name, price, img, newImg,stock = false) => ({
  type: cartActions.ADD_TO_CART,
  id,
  name,
  price,
  img,
  newImg,
  
});

export const updateToCart = (id,index) => ({
  type: cartActions.UPDATE_TO_CART,
  id,
  index  
});

export const removeFromCart = index => ({
  type: cartActions.REMOVE_FROM_CART,
  index
});

export const emptyCart = () => ({
  type: "EMPTY_CART"
});

export const cartActions = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  EMPTY_CART: "EMPTY_CART",
  UPDATE_TO_CART:"UPDATE_TO_CART"
};

export const setToken = token => ({
  type: adminActions.SET_TOKEN,
  token
});

export const unsetToken = () => ({
  type: adminActions.UNSET_TOKEN
});

export const adminActions = {
  SET_TOKEN: "SET_TOKEN",
  UNSET_TOKEN: "UNSET_TOKEN"
};

export const setUserToken = token => ({
  type: userActions.SET_USER_TOKEN,
  token
});

export const unsetUserToken = () => ({
  type: userActions.UNSET_USER_TOKEN
});



export const userActions = {
  SET_USER_TOKEN : "SET_USER_TOKEN",
  UNSET_USER_TOKEN : "UNSET_USER_TOKEN"
}

export const languageAction = {
  SET_LANGUAGE: "SET_LANGUAGE"
};

export const setLanguage = language => ({
  type: languageAction.SET_LANGUAGE,
  language
});
