import React from "react";
import Routes from "../routes/Routes";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { connect } from "react-redux";
import { Translator } from "react-auto-translate";

import { logoutUser, setToken, setUserToken, unsetToken, unsetUserToken } from "../actions";


const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
    language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem("translations")) || {
      [key]: {}
    };
    existing[key] = { ...existing[key], [language]: value };
    localStorage.setItem("translations", JSON.stringify(existing));
  }
};

function ApolloContainer(props) {

  const path = window.location.pathname;

  // const ApiUrl = "http://localhost:4000/"  // local
  const ApiUrl =  "https://test-api-zeghetmetduiten-shop.mytestpartner.nl/" //demo
  // const ApiUrl = "https://api.zeghetmetduiten.nl/" // live

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    request: operation => {
      let token = '';
      const isAdmin = path.toLowerCase().includes("admin");

      if (isAdmin) {
        token = props.adminAuth.token;
      } else  {
        token = props.userReducer.token;
      } 

      operation.setContext({
        headers: {
          Authorization:  `Bearer ${token}`  // ${props.userReducer.token}
        },

      });
    },
    onError: ({ graphQLErrors, networkError, response, operation, forward }) => {

      if (graphQLErrors) {
        if (graphQLErrors.length > 0 && graphQLErrors[0].message === "Not Authorised!") {             
          refreshToken().then(refreshedToken => {
            if(refreshedToken){
              return forward(operation);
            }else{
              console.log("false")
              return;
            }
          });    
        }      
      }
      if (networkError) {
        console.error("Network Error:", networkError);
      }
    },
    credentials: 'include',
    uri: ApiUrl  
  });

  const refreshToken = async () => {
    try {
      const isAdmin = path.toLowerCase().includes("admin");
        const payload = {
          operationName: isAdmin ? "refreshTokenAdmin" : "refreshToken",
          query:   isAdmin ? `mutation refreshTokenAdmin { refreshTokenAdmin { accessToken } }` : `
            mutation refreshToken {
              refreshToken {
                accessToken
                __typename
              }
            }
          `,
          variables: {}
        };
        
        try {
          const response = await fetch(ApiUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
            credentials: "include"
          });       
         
          const data = await response.json();     
          
          if (data && data.data && data.data.refreshToken && data.data.refreshToken.accessToken) {
            console.log("Access Token:", data.data.refreshToken.accessToken);
            const accessToken = data.data.refreshToken.accessToken;
            if (isAdmin) {
              props.dispatch(setToken(accessToken));
              props.dispatch(unsetUserToken());
            } else {
              props.dispatch(setUserToken(accessToken));
              props.dispatch(unsetToken());
            }
            return data.data.refreshToken.accessToken;
          } else {
            if (isAdmin) {
              props.dispatch(unsetToken());
            } else {
              props.dispatch(logoutUser);
              props.dispatch(unsetUserToken());
              
            }
            console.log("No access token found.");
            return null;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

    } catch (err) {
      localStorage.clear();
      console.error("Token refresh failed:", err);
      return null;
    }
  };


  return (
    <Translator
      cacheProvider={cacheProvider}
      from="nl"
      to={props.language}
      googleApiKey="AIzaSyBb2n14hBoreIGpKUqzyKKXHuDZE4Vd_30"
    >
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </Translator>
  );
}
const mapStateToProps = state => {
  const { adminAuth, userReducer, language } = state;
  return { adminAuth, userReducer, language };
};
export default connect(mapStateToProps)(ApolloContainer);
