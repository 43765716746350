import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DuitenIcon from "./DuitenIcon";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import AddToCartButton from "./AddToCartButton";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";
import { Translate } from "react-auto-translate";
import { useMediaQuery, useTheme } from "@material-ui/core";

const styles = makeStyles(theme => ({
  img: {
    height: 175,
    width: "100%",
    borderRadius: 3,
    objectFit: "contain",
  },
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 20px",
    zIndex: 40
  },
  text: {
    color: theme.palette.text.main,
    minHeight: 30,
    display: "flex",
    justifyContent: "center"
  },
  duitenContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  link: {
    textDecoration: "none",
    zIndex: 30
  },
  container: {
    padding: 12 ,
    margin: '20px 0',
    [theme.breakpoints.up('xl')]: {
      flexGrow: 0,
      maxWidth: '20%', /* 100% / 5 columns = 20% width per column */
      flexBasis: '20%',
      
    },
    [theme.breakpoints.up('md')]: {

    },
  
  

    "@media (max-width: 1900px) and (min-width: 1550px)": {
      flexBasis: "25%",
    },

    "@media (max-width: 1549px) and (min-width: 986px)": {
      flexBasis: "33.33%",
      maxWidth: "33.33%",
    },
  
    "@media (max-width: 985px) and (min-width: 960px)": {
      flexBasis: "50%",
      maxWidth: "50%",
    },

    "@media (max-width: 959px) and (min-width: 600px)": {
      flexBasis: "33.33%",
      maxWidth: "33.33%",
    },

    "@media (max-width: 286px) and (min-width: 599px)": {
      flexBasis: "50%",
      maxWidth: "50%",
    },
  },
  
  cartButton: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 250,
    "@media (max-width: 400px)": {
      width: "100%",
    },
  },
  addToCartButton: {
    marginTop: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    }
  }
}));

export default function SingleProductSmall(props) {
  const classes = styles();

  const theme = useTheme();
  const isMdUp =  useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp =  useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid className={classes.container} item xs={6} md={4} xl={2} >
      <RouterLink
        to={userRoutes.PRODUCT + props.id}
        style={{
          textDecoration: "none",
          display: "flex",
          // height: "90%",
          flexDirection: "column",
          gap: "8px",
          maxWidth: 250,
        
        }}
      >
        <LazyLoad height={175} width="100%" offset={500}>
          <img
            src={"https://shop.zeghetmetduiten.nl/images/" + props.img}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
            className={classes.img}
            alt={props.name}
          />
        </LazyLoad>

        <p  
         style={{
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
    height: "40px", // Adjust based on line height. For 2 lines with 20px line height, height should be 40px.
    lineHeight: "20px", // Adjust according to your font size
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2", // Limits text to 2 lines
    WebkitBoxOrient: "vertical", // Necessary for line clamping
    wordWrap: "break-word",
  }}  title={props.name} className={classes.text}>
  <Translate>
    {props.name}
  </Translate>
        </p>
        <div className={classes.duitenContainer}>
          <DuitenIcon />
          <span style={{marginLeft: "7px"}} className={classes.text}>{props.price}</span>
        </div>
      </RouterLink>
      <Grid item className={classes.cartButton} >
        <AddToCartButton
          id={props.id}
          name={props.name}
          price={props.price}
          img={props.img}
          newImg={props.newImg}
          className={classes.addToCartButton}
          isHideText={props.isCategory}
          stockAmount={props.stockAmount}
        />
      </Grid>
    </Grid>
  );
}
