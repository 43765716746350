import { userActions } from "../actions";

const userReducer = (
  state = {
    token: null
  },
  action
) => {
  switch (action.type) {
    case userActions.SET_USER_TOKEN:
      return { token: action.token };
    case userActions.UNSET_USER_TOKEN:
      return { token: null };
    default:
      return state;
  }
};

export default userReducer;
