import { gql } from "apollo-boost";

export const CUSTOMER_BY_CODE = gql`
  query customerByCode($code: String!) {
    customerByCode(code: $code) {
      firstName
      insertion
      lastName
      id
      code
      email
      gender
      duiten
      company {
        name
        endDate
      }
      token
    }
  }
`;

export const ORDERS_BY_CUSTOMER_CODE = gql`
  query customerByCode($code: String!) {
    customerByCode(code: $code) {
      firstName
      insertion
      lastName
      id
      code
      email
      gender
      duiten
      company {
        name
        endDate
      }
      order {
        id
        createdAt
        products {
          price
          hasProduct {
            id
            name
            price
            images {
              location
              new
            }
          }
        }
      }
    }
  }
`;

export const SET_CUSTOMER_DUITEN = gql`
  mutation setCustomerDuiten($id: ID!, $duiten: Int!) {
    setCustomerDuiten(id: $id, duiten: $duiten) {
      id
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder($customer: ID!, $products: [ID!]) {
    placeOrder(customer: $customer, products: $products) {
      id,
      success,
      products {
        id
        name
        stock
      }
    }
  }
`;

export const ASSIGN_PRODUCT = gql`
  mutation assignProduct($customer: ID!, $products: [ID!], $sendemail: Boolean!) {
    assignProduct(customer: $customer, products: $products, sendemail: $sendemail) {
      id,
      success,
      products {
        id
        name
        stock
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($customer: ID!) {
    createOrder(customer: $customer) {
      id
    }
  }
`;

export const PROCESS_ORDER = gql`
  mutation processOrder($id: ID!) {
    processOrder(id: $id) {
      id
    }
  }
`;

export const CREATE_BOUGHT_PRODUCT = gql`
  mutation createBoughtProduct(
    $belongsTo: ID!
    $hasProduct: ID!
    $price: Int!
  ) {
    createBoughtProduct(
      belongsTo: $belongsTo
      hasProduct: $hasProduct
      price: $price
    ) {
      id
      belongsTo {
        id
      }
      hasProduct {
        name
      }
      price
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $code: String!
    $firstName: String!
    $insertion: String
    $lastName: String!
    $email: String!
    $gender: String!
    $duiten: Int!
    $company: ID!
  ) {
    createCustomer(
      code: $code
      firstName: $firstName
      insertion: $insertion
      lastName: $lastName
      email: $email
      gender: $gender
      duiten: $duiten
      company: $company
    ) {
      firstName
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id: ID!
    $firstName: String!
    $insertion: String
    $lastName: String!
    $email: String!
    $gender: String!
    $duiten: Int!
  ) {
    updateCustomer(
      id: $id
      firstName: $firstName
      insertion: $insertion
      lastName: $lastName
      email: $email
      gender: $gender
      duiten: $duiten
    ) {
      firstName
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation removeCustomer($id: ID!) {
    removeCustomer(id: $id) {
      code
        id
        firstName
        insertion
        lastName
        email
        gender
        duiten
        order {
          id
          processed
          createdAt
          products {
            id
            price
          }
        }
    }
  }
`;

export const CREATE_CONTACT_FORM = gql`
  mutation createContactForm(
    $name: String!
    $phone: String!
    $email: String!
    $employer: String!
    $code: String!
    $details: String!
  ) {
    createContactForm(
      name: $name
      phone: $phone
      email: $email
      employer: $employer
      code: $code
      details: $details
    ) {
      name
    }
  }
`;
