import React, { useState } from "react";
import { editModalStyle } from "../styles/editModalStyle";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { CREATE_USER, UPDATE_USER } from "../queries-mutations/adminAuth";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Button from "@material-ui/core/Button";
import { FormControl, Table, TableBody, TableRow, TableHead, TableContainer, TableCell } from "@material-ui/core";
import ProductSelector from "./ProductSelector";
import CategorySelector from "./CategorySelector";
import { Typography } from "@material-ui/core";
import { Translate } from "react-auto-translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControlLabel } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import AdminImageDisplay from "./AdminImageDisplay";

import {
  CUSTOMER_BY_CODE,
  ASSIGN_PRODUCT,
} from ".././queries-mutations/customers";
import { Grid } from "@material-ui/core";

export default function Employee_ProductModal(props) {
  const classes = editModalStyle();
  const [state, setState] = useState({
    code: props.customer ? props.customer.code : "",
    id: props.customer ? props.customer.id : "",
    email: props.customer ? props.customer.email : "",
    firstName: props.customer ? props.customer.firstName : "",
    duiten: props.customer ? props.customer.duiten : "",
    selectedProducts: [],
    addedProducts: [],
    removedProducts: [],
    sendemail: false,
    title: '',
  });

  const [placeOrder] = useMutation(ASSIGN_PRODUCT);

  const [bezig, setBezig] = useState(false);

  const [bestellingStatus, setBestellingStatus] = useState(<div />);
  const [productdropdown, setProductdropdown] = useState(<div/>);
  const [productlist, setProductList] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  if(props.customer.length > 0){
  state.code = props.customer[0].code.props.children[1];
  state.title = "Mutiple Users";
  }
  else{
    state.title = state.firstName;
  }
  const { loading, error, data, refetch } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: state.code },
  });

  const search = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return myArray[i];
      }
    }
  };

  const handleCheckChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleParentChange = (event, data) => {
    setState({ ...state, selectedCategory: data });
    console.log(data, "==> data");
    if(data){
    setProductdropdown(
      <FormControl className={classes.input}>
      <ProductSelector
        onSelectProduct={onSelectProduct}
        onRemoveProduct={onRemoveProduct}
        selectedProducts={state.selectedProducts}
        selectedCategory={data.id}
      />
    </FormControl>
    );
    }
    else{
      setProductdropdown(<div/>);
    }
  };

  const onSelectProduct = (data, context) => {
    setState({ ...state, selectedProducts: data });
    setState({
      ...state,
      addedProducts: [...state.addedProducts, data],
    });
    var element = [];
    if(data){
    for(let val of data)
    {
      element.push(<TableRow><TableCell>{val.name}</TableCell><TableCell>{val.description.replace(/(<([^>]+)>)/ig, '')}</TableCell><TableCell>{val.price}</TableCell><TableCell>{val.code}</TableCell><TableCell>{val.stockAmount}</TableCell><TableCell><AdminImageDisplay
      images={val.images} assigntable="assigntable"
    /></TableCell></TableRow>);
    }
    setProductList(element);
  }
  };

  const onRemoveProduct = (data, context) => {
    // setState({ ...state, selectedProducts: data });
    // setState({...state, addedProducts: [data] });
    setState({
      ...state,
      selectedProducts: data,
      addedProducts: [data],
      removedProducts: [...state.removedProducts, context],
    });
    var element = [];
    if(data){
      for(let val of data)
      {
        element.push(<TableRow><TableCell>{val.name}</TableCell><TableCell>{val.description.replace(/(<([^>]+)>)/ig, '')}</TableCell><TableCell>{val.price}</TableCell><TableCell>{val.code}</TableCell><TableCell>{val.stockAmount}</TableCell><TableCell><AdminImageDisplay
        images={val.images} assigntable="assigntable"
      /></TableCell></TableRow>);
      }
      setProductList(element);
    }
  };

  const assignProduct = () => {
   
    try{
    console.log(props.customer);
   if(props.customer.length == undefined){
    setBezig(true);
    refetch().then(() => {
      if (data) {
        let duiten = data.customerByCode.duiten;
        let totaal = state.addedProducts[0]
          .map((o) => o.price)
          .reduce((a, c) => {
            return a + c;
          });
          //console.log(state.addedProducts[0].map((o) => o));
        console.log(totaal, duiten);
        if (totaal > duiten) {
          setBestellingStatus(
            <Typography className={classes.warning}>
              <Translate>Je hebt niet genoeg duiten!</Translate>
            </Typography>
          );
          setIsSubmitting(false); 
        } else if(totaal != duiten){
          setBestellingStatus(
            <Typography className={classes.warning}>
              <Translate>Je hebt nog niet al je duiten opgemaakt!</Translate>
            </Typography>
          );
          setIsSubmitting(false); 
        } else {
          let products = [];
          let out = false;
          state.addedProducts[0].map((product) => {
            products.push(product.id);
          });
          products.map((productID) => {
            let found = [];
            products.map((counter) => {
              if (counter === productID) {
                found.push(counter);
              }
            });
            let checkProduct = search(productID, state.addedProducts[0]);
            if (checkProduct.stockAmount < found.length) {
               out = true;
            }
          });
          if (out) {
            setBestellingStatus(
              <Typography className={classes.warning}>
                <Translate>
                  Een van de producten die je hebt gekozen is helaas op, kies
                  alsjeblieft iets anders
                </Translate>
              </Typography>
            );
            setIsSubmitting(false); 
          }else {
            console.log("place order");
            placeOrder({
              variables: {
                customer: data.customerByCode.id,
                products: products,
                sendemail: state.sendemail
              },
            }).then((res) => {
              console.log(res, "res");
              if(res.data.assignProduct.success){
                props.onClose();
                setTimeout(() => {
                  setIsSubmitting(false); 
                }, 1000);
              }else{
                
            let outOfStock = res.data.assignProduct.products
            const uniqueOutOfStock = removeDuplicates(outOfStock);
            const inStock = uniqueOutOfStock.filter((item) => item.stock > 0);     
          
            let namelist = uniqueOutOfStock.map(item => `${item.name},`);

  

            if (inStock.length === 0) {
              alert(`U kunt helaas geen order plaatsen want, ons artikel ${namelist} is uit onze voorraad, kies een ander artikel.`);
     
            } else {          
              alert(` U kunt helaas geen order plaatsen want, ons artikel ${namelist} is uit onze voorraad, kies een ander artikel.`);
            }
          }              
            
              
              //history.push(userRoutes.SUCCESS);
            });
          }
        }
      } else if (loading) {
        return (
          <div>
            <CircularProgress color="secondary" />
          </div>
        );
      } else if (error) {
        setIsSubmitting(false); 
        return (
          <div>
            <h2>
              <Translate>
                Er ging iets fout, probeer het later opnieuw
              </Translate>
            </h2>
          </div>
        );
      }
    });
    setBezig(false);
  } else{
    console.log("else");
    setBezig(true);
    var count =1;
    props.customer.map( val =>{  
    var code = val.code.props.children[1];
    var duitenleft = val.duiten.props.children[1];
      if (data) {
        let duiten = duitenleft;
        let totaal = state.addedProducts[0]
          .map((o) => o.price)
          .reduce((a, c) => {
            return a + c;
          });
          console.log(totaal);
        if (totaal > duiten) {
          setBestellingStatus(
            <Typography className={classes.warning}>
              <Translate>Je hebt niet genoeg duiten!</Translate>
            </Typography>
          );
          setIsSubmitting(false); 
        } else {
          let products = [];
          let out = false;
          state.addedProducts[0].map((product) => {
            products.push(product.id);
          });
          products.map((productID) => {
            let found = [];
            products.map((counter) => {
              if (counter === productID) {
                found.push(counter);
              }
            });
            let checkProduct = search(productID, state.addedProducts[0]);
            if (checkProduct.stockAmount < found.length) {
              // props.cart.map((product, index) => {
              //   product.id === productID &&
              //     props.dispatch(removeFromCart(index));
              // });
               out = true;
            }
          });
          console.log(products);
          if (out) {
            setBestellingStatus(
              <Typography className={classes.warning}>
                <Translate>
                  Een van de producten die je hebt gekozen is helaas op, kies
                  alsjeblieft iets anders
                </Translate>
              </Typography>
            );
            setIsSubmitting(false); 
          } else {
            console.log("place order2");
            placeOrder({
              variables: {
                customer: val.id,
                products: products,
                sendemail: state.sendemail
              },
            }).then(() => {
              if(count == props.customer.length){
              props.onClose();
              setTimeout(() => {
                setIsSubmitting(false); 
              }, 1000);
            }
            count++;

              //history.push(userRoutes.SUCCESS);
            });
          }
        }
      } else if (loading) {
        return (
          <div>
            <CircularProgress color="secondary" />
          </div>
        );
      } else if (error) {
        setIsSubmitting(false); 
        return (
          <div>
            <h2>
              <Translate>
                Er ging iets fout, probeer het later opnieuw
              </Translate>
            </h2>
          </div>
        );
      }
});
  setBezig(false);
  }
} catch (error) {
  console.error("Error placing order:", error);
} finally {

 // Reset the submission flag regardless of success or failure
}
};


function removeDuplicates(array) {
  const uniqueIds = new Set();
  return array.filter((item) => {
    const isDuplicate = uniqueIds.has(item.id);
    uniqueIds.add(item.id);
    return !isDuplicate;
  });
}


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.parent}
      >
        <Paper className={classes.prodmodalStyle}>
          {props.user ? (
            <h1>Gebruiker bijwerken</h1>
          ) : (
            <h1>{state.title}</h1>
          )}
        <form
        onSubmit={async (event) => {
          event.preventDefault();
          if (isSubmitting) return;    
          setIsSubmitting(true);
          await assignProduct();    
        }}
        >
            <FormControl className={classes.input}>
              <CategorySelector
              label="Selecteer product"
              parent={state.selectedCategory}
              onChange={handleParentChange}
              />
            </FormControl>
            <br />
            {productdropdown}
            <br />
            <FormControlLabel
        className={[classes.input, classes.lastInput]}
        control={
          <Switch
            checked={state.sendemail}
            onChange={handleCheckChange}
            name="sendemail"
            label="sendemail"
          />
        }
        label="Verstuur email"
      />
      <br />
            <Grid items xs={12}>
              {bestellingStatus}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.input}
              type="submit"
              disabled={isSubmitting}
            >
              Opslaan
            </Button>
          </form>
          <h3 className={[classes.input, classes.lastInput,classes.clear]}>
          Geselecteerdeproducten
          </h3>
          <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
               Naam product
              </TableCell>
              <TableCell>
               Beschrijving
              </TableCell>
              <TableCell>
               Prijs
              </TableCell>
              <TableCell>
                Code
              </TableCell>
              <TableCell>
               Voorraad
              </TableCell>
              <TableCell>
                Images
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {productlist}
            </TableBody>
          </Table>
          </TableContainer>
        </Paper>
      </Modal>
    </div>
  );
}
