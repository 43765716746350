import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { CATEGORY, categories } from "../../queries-mutations/products";
import parse from "html-react-parser";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SingleProductSmall from "../../components/SingleProductSmall";
import { Translate } from "react-auto-translate";

import { connect } from "react-redux";
import categoryBanner from "../../assets/1.png";
import FullWidthImage from "../../components/FullWidthImage";
import { GET_BANNER_BY_CATEGORY } from "../../queries-mutations/banners";
import { Backdrop, ClickAwayListener, createTheme, FormControl, List, ListItem, ListItemText, Paper, Popover, Popper, useMediaQuery, useTheme } from "@material-ui/core";
import { userRoutes } from "../../routes/Users/constants";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CategoryBack from "../../components/CategoryBack";

import { Select, MenuItem, InputLabel } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  previousContainer: {
    margin: "30px auto",
    "@media (max-width: 960px)": {
      margin: "unset",
    },
  },
  imageContainer: {
    width: "100%",
    // margin: "10px 0px",
    "@media (max-width: 600px)": {
      margin: "unset",
    },
  },
  text: {
    textAlign: "left",
    margin: "0px 10px",
    color: theme.palette.text.main,
  },
  sidebar: {
    width: "160px",
    textAlign: "left",
    backgroundColor: "#F0F0F0",
    padding: "10px",
    borderRadius: "6px",
    height: "fit-content",
  },
  sidebarDiv: {

    height: "fit-content", // Adjust based on your layout needs
    overflowY: "auto", // Enable vertical scrolling
    position: "sticky",
    top: 0, // Keep the sidebar at the top while scrolling
    "@media (max-width: 1200px)": {
      top: "50px",
    }
  },
  selectedCategory: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // color: theme.palette.text.alt,
    // backgroundColor: theme.palette.secondary.main,
    borderRadius: "3px",
    width: "160px",
    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    }
  },
  selectedCategoryFooter:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    borderRadius: "3px",
    "&:hover": {

      backgroundColor: theme.palette.secondary.main,
    }
  },
  categoryList: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      borderRadius: "3px",
    },
  },
  categoriesName: {
    height: "20px",
  },
  bullet: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-25%)',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,

  },
  contentContainer: {
    color: "#333333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "500",
    backgroundColor: '#F0F0F0',
    "@media (max-width: 600px)": {
      minHeight: 250
    },

  },
  popperPaper: {
    width: "180px",
    backgroundColor: "#F0F0F0",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: theme.shadows[3],
  },
  popper: {
    zIndex: 1000,
    // position: "fixed",
    bottom: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  headerContainer: {
    padding: '10px',

    "@media (max-width: 960px)": {

      zIndex: theme.zIndex.drawer + 1, // Ensure it stays on top
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',


    }

  },
  footerContainer:{
   margin: 'unset',
   paddingTop: '10px',
  },


  footer: {
    backgroundColor: "#F0F0F0",

  },
  footerTitle: {
 margin: 'unset',
 padding: 12,
  },
  footerList: {
    // gap: theme.spacing(2),
  },
  footerItem: {
    // width: "fit-content",
    // float: "left",
  },
  stickyGrid :{
    // "@media (max-width: 959px)": {
    //   position: "sticky",
    //   top: 137,
    //   zIndex: 1000,
    //   backgroundColor: "white",
    // },

    // "@media (max-width: 600px)": {
    //   top: 195
    // }
  },

}));

function SingleCategory(props) {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();


  const [bannerImage, setBannerImage] = useState(categoryBanner);
  const [selectedCat, setSelectedCat] = useState(id); // Manage selected category
  // const [history, setHistory] = useState([id]);
  const [categoryHistory, setCategoryHistory] = useState([id]);
  const {
    loading: overviewLoading,
    error: overviewError,
    data: overviewData,
  } = useQuery(categories);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXxsScreen = useMediaQuery('(max-width:450px)');

  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
    refetch,
  } = useQuery(CATEGORY, {
    variables: { id: selectedCat }, // Use selectedCat instead of id directly
  });


  const {
    loading: bannerLoading,
    error: bannerError,
    data: bannerData,
    refetch: refetchBanner,
  } = useQuery(GET_BANNER_BY_CATEGORY, {
    variables: { id: selectedCat }, // Use selectedCat instead of id directly
  });

  let categoryNames = [];

  if (overviewData) {
    overviewData.categories = overviewData.categories.filter(
      (category) => category.published
    );
    categoryNames = overviewData.categories.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  }

  const handleFilterClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      bannerData &&
      bannerData.bannerByCategory.length > 0 &&
      bannerData.bannerByCategory[0].image &&
      bannerData.bannerByCategory[0].activeStatus
    ) {
      setBannerImage(
        `https://shop.zeghetmetduiten.nl/images/${bannerData.bannerByCategory[0].image}`
      );
    } else {
      setBannerImage(categoryBanner); // Reset to default banner if no active banner is found
    }
    refetchBanner();

  }, [selectedCat, bannerData, refetchBanner]);

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo(0, 0);   
      refetch({ id: selectedCat })
      .then(({ data }) => {
        console.log("Refetched data:", data);
      })
      .catch((error) => {
        console.error("Error refetching data:", error);
      });
    }, 300);

  }, [bannerData]);

  const handleCategoryChange = (event) => {
    setCategoryHistory((prevHistory) => [...prevHistory, event.target.value]);
    setSelectedCat(event.target.value); // Update selected category on change
  };

  const handleCategoryClick = (categoryId) => {

    setCategoryHistory((prevHistory) => [...prevHistory, categoryId]);
    setSelectedCat(categoryId);
    handleClickAway();
    window.scrollTo(0, 0);
  };


  const goBack = () => {
    if (categoryHistory.length > 1) {
      const previousCategory = categoryHistory[categoryHistory.length - 2];
      setCategoryHistory((prevHistory) =>
        prevHistory.slice(0, -1)
      );
      setSelectedCat(previousCategory);
      history.push(`${userRoutes.CATEGORY}${previousCategory}`);
    } else {
      history.push("/users");
    }

  }

  if (categoryError || bannerError || overviewError) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets mis, probeer het later opnieuw</Translate>
        </h2>
      </div>
    );
  }

  if (!categoryData || !categoryData.category) {
    return (
      <div>
        <h1>
          <Translate>Helaas konden we deze categorie niet vinden!</Translate>
        </h1>
      </div>
    );
  }

  return (
    <>
      <Grid container >
        <Grid xs={false} md={1} />

        <Grid item xs={12} sm={12} md={10} xl={10} className={classes.imageContainer}>
          <Grid container className={classes.imageContainer} style={{ marginBottom: '20px' , marginTop: '5px' }}>
            <Grid item xs={12} sm={6} md={6} >
              <img src={bannerImage} overlay={!!bannerData} style={{ width: '100%', height: '100%' }} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.contentContainer}>
              {bannerData?.bannerByCategory[0]?.description ? (
                parse(bannerData.bannerByCategory[0].description)
              ) : (
                <>
                  <Translate>Welkom op de online geschenkenmarkt!</Translate>
                  <br /><br />
                  <Translate>Hier stel je jouw cadeaupakket samen.</Translate>
                  <br />
                  <Translate>Klik op de kramen en ga op zoek naar</Translate>
                  <br />
                  <Translate>jouw favoriete producten.</Translate>
                  <br /><br />
                  <Translate>Veel shopplezier!</Translate>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container >
        <Grid xs={false} md={1} />
        <Grid item xs={12} md={10} xl={10}>
          <Grid container  >
            <Grid item xs={12}
              style={isMdUp ? { flex: '0 0 190px', maxWidth: '190px' } : {}}>


              {!isMobile && (
                <div className={classes.sidebarDiv}>
                  <Grid className={classes.sidebar}>
                    <h2 style={{ color: "#333333" }}>
                      <Translate>Categorieën</Translate>
                    </h2>
                    <List>
                      {categoryNames.map((category) => (
                        <ListItem
                          key={category.id}
                          button
                          onClick={() => handleCategoryClick(category.id)}
                          className={selectedCat === category.id ? classes.selectedCategory : classes.categoryList}
                        >
                          <div className={classes.bullet} />
                          <ListItemText primary={<Translate>{category.name}</Translate>} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid className={classes.previousContainer}>
                    <CategoryBack back={goBack} />
                  </Grid>
                </div>
              )}


            </Grid>

            <Grid item xs={12}
              style={isMdUp ? { flex: '1 1 calc(100% - 190px)', maxWidth: 'calc(100% - 190px)', marginBottom: '20px' } : { marginBottom: '20px' }}>
              <Grid item xs={12} md={12} xl={12} className={` ${classes.text} ${classes.stickyGrid}` }>
                <div className={classes.headerContainer}>
                {isMobile && (

                  // <Grid className={classes.previousContainer}>
                  //   <CategoryBack back={goBack} />
                  // </Grid>
                  <div></div>

              )}

                  <h2 style={{ color: "#0D7C5F"}}>
                    <Translate>{categoryData.category.name}</Translate>
                  </h2>
                  {isMobile && (<>  <FilterList fontSize="large" style={{ cursor: "pointer" }} onClick={handleFilterClick} />

                    <Popover
                      // id={id}
                      // open={open}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      elevation={3}
                      onClose={handleClickAway}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      PaperProps={{ className: ` category-popover` }}
                    >
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Paper className={classes.popperPaper}>
                          <List>
                             <ListItemText primary={<Translate> <span style={{fontSize: '1.2rem',fontWeight: 500}}>Selecteer Categorie </span></Translate>} />
                            {categoryNames.map((category) => (
                              <MenuItem
                                key={category.id}
                                onClick={() => handleCategoryClick(category.id)}
                                className={selectedCat === category.id ? classes.selectedCategory : classes.categoryList}
                              >
                                <ListItemText primary={<Translate>{category.name}</Translate>} />
                              </MenuItem>
                            ))}
                          </List>
                        </Paper>
                      </ClickAwayListener>
                    </Popover>
                  </>)}
                </div>
                {parse(categoryData.category.description)}
              </Grid> 
                <Grid item xs={12} md={12} xl={12} className={classes.text}>
                    <Grid container>
                        {categoryData.category.products
                            ?.filter(
                                (product) =>
                                    product.published &&
                                    product.stockAmount > 0 &&
                                    product.price <= props.userInformation.duiten
                            )
                            .sort((a, b) => a.order - b.order)
                            .map((product) => (
                                <SingleProductSmall
                                    key={product.id}
                                    newImg={
                                        product.images && product.images[0] ? product.images[0].new : false
                                    }
                                    img={
                                        product.images && product.images[0]
                                            ? product.images[0].location
                                            : null
                                    }
                                    name={product.name}
                                    price={product.price}
                                    id={product.id}
                                    isCategory={true}
                                    stockAmount={product.stockAmount}
                                />
                            ))}
                    </Grid>
                </Grid>

              {isMobile && (
            <Grid item xs={12} md={12} xl={12} className={classes.text}>
           <Grid className={classes.previousContainer}>
             <CategoryBack back={goBack} />
           </Grid>
  </Grid>
       )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  const { userInformation } = state;
  return { userInformation };
};

export default connect(mapStateToProps)(SingleCategory);
