import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ADMIN_OVERVIEW_PRODUCTS } from "../queries-mutations/products";
import { Multiselect } from "multiselect-react-dropdown";

export default function ProductSelector({ selectedCategory, selectedProducts, onSelectProduct, onRemoveProduct }) {
  const { loading, error, data } = useQuery(ADMIN_OVERVIEW_PRODUCTS, {
    returnPartialData: false,
    errorPolicy: "ignore"
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! {error.message}</div>;  


  const productList = data?.products
    ?.map((prod) => ({
      ...prod, 
      category: prod.categories.length > 0 ? prod.categories[0].id : "",
      displayValue: `${prod.name} - €${prod.price}`
    }))
    .filter((prod) => prod.category === selectedCategory) 
    .filter((product) => product.published && product.stockAmount > 0); 

    console.log(productList,"productList");

  return (
    <Multiselect
      placeholder="Selecteer"
      options={productList} 
      selectedValues={selectedProducts} 
      onSelect={onSelectProduct}
      onRemove={onRemoveProduct}
      displayValue="displayValue"
    />
  );
}
